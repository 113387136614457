@import 'src/styles/variables';

.link {
	display: flex;
	justify-content: center; 
	align-items: center;
	padding: 0 $padding;
	overflow:hidden; 
}

.logo {
	width: 100%; 
}

.logoconnexion{
	position: relative; 
	left: 60px; 
	width: 100%; 
}
