@import 'mixins';

// Maison Leclaire
$yellow: #ffe15d;
$grey: #CCD1D1;
$lightGreen: #86d723;
$background: #f2eef6;

// The Buyer
$oldRose: #c78786;
$darkBlue: #202334;

// Stats The Buyer
$watermelon: #ff5c79;

// Main colors
$green: #86d723;
$red: #fc5e27;
$black: #000;

// Greys
$darkGrey: #646464;
$grey: #999999;
$lightGrey: #e5e1ea;
$ultraLightGrey: #faf8fb;
$line: #f1eef5;

// Refs
$primary: $grey;
$secondary: $black;
$text: $black;

// Shadows
$shadow: 0 2px 15px rgba(219, 221, 226, 0.5);
$greyShadowHeight: 25px;

// Radius
$radius: 8px;

// Margin / Padding
$padding: 20px;

// Header
$headerHeight: (
	small: 60px,
	big: 80px,
);

// Footer
$footerHeight: (
	small: map-get($headerHeight, small),
	big: map-get($headerHeight, big),
);

// Taille du cercle avec initiales du BtnMenu
$btnMenuCircleSize: 38px;

// Hauteur de la barre des filtres
$filtresHeight: 45px;

// Fonts
$graphik: 'graphik', arial, sans-serif;
$font: $graphik;

// Transitions
$duration: 0.2s;
$quint: cubic-bezier(0.7, 0, 0.3, 1);
$bounce: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$easeInOutQuad: cubic-bezier(0.46, 0.03, 0.52, 0.96);

// Breakpoints
$breakpoints: (
	mobile: 425px,
	tablet: 768px,
	laptop: 1024px,
	desktop: 1440px,
	xxl: 2560px,
);
